import React, { CSSProperties, useEffect, useState } from 'react';
import { Grid, Row, Col, Space, Typography, Button } from 'antd';
import {  useNavigate, useSearchParams } from 'react-router-dom';
import { CopyOutlined, ZoomInOutlined } from '@ant-design/icons';
import './CardDataList.scss';
import moment from 'moment';
import { formatNumberCurrency } from 'utils/number';
const { Text } = Typography;
interface CardDataProps {
  dataList: any,
  notiApi: any,
  sumTurnOver: number,
  sumWinLoss: number
};

export const CardDataList: React.FC<CardDataProps> = ({ dataList, notiApi, sumTurnOver = 0, sumWinLoss = 0 }) => {
  const { useBreakpoint } = Grid;
  const breakpoints = useBreakpoint();
  const [loading, setLoading] = useState(false);
  const [searchParams] = useSearchParams();
  const [activeTab, setActiveTab] = useState('statement');
  const pageParam = searchParams.get('page');
  const navigate = useNavigate();
  useEffect(() => {
    if (pageParam) {
      setActiveTab(pageParam)
    }
  }, [pageParam])


  const rootStyle: CSSProperties = {
    padding: (() => {
      if (!breakpoints.lg) {
        return '10px 5px'
      } else {
        return '30px 50px'
      }
    })(),
  }
  const copyToClipboard = async (gameRef: string) => {
    await navigator.clipboard.writeText(gameRef);
    notiApi.success({
      message: `คัดลอกแล้ว/Copyed !`,
      description: gameRef,
      placement: 'topRight'
    });
  };

  const openGame = (gameCompanyId: string, gameCode: string) => {
    navigate(`/lobby/hot?forceOpen=${gameCompanyId}&forceGameCode=${gameCode}`)
  }
  return (
    <div className="card-data-list">
      <div className='sum-row'>
        <Row>
          <Col span={14} style={{color:'#838383', textAlign: 'right'}}>
            Total bet
          </Col>
          <Col span={10} style={{ textAlign: 'right' }}>
            {formatNumberCurrency(sumTurnOver,2,2)}
          </Col>
        </Row>
        <Row>
          <Col span={14} style={{color:'#838383', textAlign: 'right'}}>
            & win/loss
          </Col>
          <Col span={10} style={{ textAlign: 'right' }}>
            <Text strong type={sumWinLoss > 0 ? 'success' : 'danger'}>{sumWinLoss > 0 ? '+' : ''} {formatNumberCurrency(sumWinLoss, 2, 2)}</Text>
          </Col>
        </Row>
      </div>
      {dataList.map((record: any, index: number) => (
        <div className='row-data' key={record.gameRef}>
          <Row key={record.gameRef}>
            <Col span={12}>
              <Space>  Ref. <Button size="small" iconPosition={'end'} style={{ fontSize: '12px', color: '#a6a6a6' }} type="text" icon={<CopyOutlined />} onClick={() => copyToClipboard(`${record.gameRef}`)}>
                <Text style={{ maxWidth: '100px' }} ellipsis={true}>{record.gameRef}</Text>
              </Button>
              </Space>
            </Col>
            <Col span={12} style={{ textAlign: 'right' }}>
              <span className='title'> 
                <Button size="small" iconPosition={'end'} style={{ fontSize: '12px', color: '#a6a6a6' }} type="text" icon={<ZoomInOutlined />} onClick={() => openGame(record.gameCompanyId, record.gameCode)}>
                <Text style={{ maxWidth: '100px' }} ellipsis={true}>{record.gameCompany}</Text>
              </Button>
              </span>
            </Col>
          </Row>
          <Row>
            <Col span={4}>
              <span className='title'>Bet</span>
            </Col>
            <Col span={12}>
              <span className='title'> {moment(record.betTime).format('DD/MM/YYYY HH:mm:ss')}</span>
            </Col>
            <Col span={8} style={{ textAlign: 'right' }}>
              <span> {formatNumberCurrency(record.betAmount, 2, 2)}</span>
            </Col>
          </Row>
          <Row>
            <Col span={4}>
              <span className='title'>Winloss</span>
            </Col>
            <Col span={12}>
              <span className='title'> {moment(record.settleTime).format('DD/MM/YYYY HH:mm:ss')}</span>
            </Col>
            <Col span={8} style={{ textAlign: 'right' }}>
              <Text style={{ textAlign: 'right', width: '100%' }} type={record.settleAmount > 0 ? 'success' : 'danger'}>{record.settleAmount > 0 ? '+' + formatNumberCurrency(record.settleAmount, 2, 2) : formatNumberCurrency(record.betAmount * -1, 2, 2)} </Text>
            </Col>

          </Row>

        </div>
      ))}
    </div>
  )
}
