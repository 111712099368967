import React, { CSSProperties, useEffect, useState } from 'react';
import { Button, Flex, Grid, Layout, Table, TableColumnsType, TableProps, DatePicker, Form, Segmented, Tabs, Space, Pagination, Tooltip, Typography, Card } from 'antd';
import { searchBetList } from 'api/betHistoryService';
import moment from 'moment';
import { format } from 'path';
import { formatNumberCurrency } from 'utils/number';
import dayjs from 'dayjs';
import { getStatement } from 'api/statementService';
import IntlMessages from 'components/Common/IntlMessages';
import { CardDataList } from './CardDataList/CardDataList';
interface DataType {
  txnId: React.Key;
  action: number;
  after: number;
  before: number;
  type: string;
  createDate: string;
}

interface betHistProp {
  notiApi: any
}
const { Text } = Typography;

export const StatementTab: React.FC<betHistProp> = ({ notiApi }) => {
  const { useBreakpoint } = Grid;
  const breakpoints = useBreakpoint();
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();
  const [data, setData] = useState<DataType[]>([]);
  const [totalDep, setTotalDep] = useState(0);
  const [totalWith, setTotalWith] = useState(0);
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 2,
    total: 0,
  });


  useEffect(() => {
    getTransactionBank({ DatePicker: [dayjs(), dayjs()] })
  }, [])

  const getTransactionBank = async (values: any, page: number = 1, pageSize: number = 50) => {
    setLoading(true);
    try {
      const { DatePicker } = values;
      const statementDatas = await getStatement(page, pageSize, Number(DatePicker[0].startOf('day').valueOf()), Number(DatePicker[1].valueOf()));
      setTotalDep(statementDatas.result.sumDeposit);
      setTotalWith(statementDatas.result.sumWithdraw);
      setData(statementDatas.result.moneyList)
      setPagination({
        current: statementDatas.result.pageIndex,
        pageSize: statementDatas.result.rowPerPage,
        total: statementDatas.result.total,
      });
    } catch (error) {
      console.error('Failed to fetch data');
    } finally {
      setLoading(false);
    }
  }

  const onSearch = (values: any) => {
    getTransactionBank(values)
  }

  const handleTableChange = (page: number, pageSize: number) => {
    const values = form.getFieldsValue();
    getTransactionBank(values, page, pageSize);
  };

  const displayContent: CSSProperties = {
    display: (() => {
      if (!breakpoints.lg) {
        return 'block'
      } else {
        return 'flex'
      }
    })(),

  }

  const buttonStyle: CSSProperties = {
    paddingTop: (() => {
      if (!breakpoints.lg) {
        return '10px'
      } else {
        return '0px'
      }
    })(),
  }

  const layoutLobby: CSSProperties = {
    paddingTop: (() => {
      if (!breakpoints.lg) {
        return '0'
      } else {
        return '20px'
      }
    })(),
  }

  const copyToClipboard = async (gameRef: string) => {
    await navigator.clipboard.writeText(gameRef);
    notiApi.success({
      message: `Copy to clipboard`,
      description: gameRef,
      placement: 'topRight'
    });
  };



  const columns: TableColumnsType<DataType> = [
    {
      title: <IntlMessages id="history.ref" />,
      dataIndex: 'txnId',
      align: 'center',
      onCell: (record) => {
        return {
          onClick: (ev) => {
            copyToClipboard(`${record.txnId}`);
          },
        };
      },
      render: (uuid: string) => <Tooltip placement="top" title={"คลิกเพื่อคัดลอก " + uuid}> <Text style={{ maxWidth: '100px' }} ellipsis={true}>{uuid}</Text> </Tooltip>
    },
    {
      title: <IntlMessages id="history.transactionTime" />,
      align: 'center',
      dataIndex: 'createDate',
      render: (record) => moment(record).format('DD-MM-YYYY HH:mm:ss')
    },
    {
      title: <IntlMessages id="history.deposit" />,
      dataIndex: 'action',
      align: 'right',
      render: (action, record) => {
        return record.type === 'Deposit-mem' ? formatNumberCurrency(action, 2, 2) : ''
      }
    },
    {
      title: <IntlMessages id="history.withdraw" />,
      align: 'right',
      dataIndex: 'action',
      render: (action, record) => {
        return record.type === 'Withdraw-mem' ? formatNumberCurrency(action, 2, 2) : ''
      }
    },
    {
      title: <IntlMessages id="history.before" />,
      align: 'right',
      dataIndex: 'before',
      render: (record) => {
        return formatNumberCurrency(record, 2, 2)
      }
    },
    {
      title: <IntlMessages id="history.after" />,
      align: 'right',
      dataIndex: 'after',
      render: (record) => {
        return formatNumberCurrency(record, 2, 2)
      }
    },
    {
      title: 'Remark',
      dataIndex: 'remark',
    },
  ];

  const { RangePicker } = DatePicker

  return (
    <div key={'bet-tab'}>
      <Form
        form={form}
        name="control-hooks3"
        onFinish={onSearch}
        layout={breakpoints.xs ? 'vertical' : 'inline'}
        className='form-search'
        style={breakpoints.xs ? { marginBottom: '10px' } : { marginBottom: '30px' }}
        initialValues={{ DatePicker: [dayjs().startOf('day'), dayjs()] }}
      >
        <Space style={displayContent}>
          <Form.Item
            label={<IntlMessages id="history.searchDate" />}
            name="DatePicker"
            className='mb-0'
            rules={[{ required: true, message: 'Please select a date range' }]}
          >
            <RangePicker size='large'
              minDate={dayjs().subtract(32, 'days')}
              maxDate={dayjs()}
              format={'DD/MM/YYYY'}
            />
          </Form.Item>
          <Form.Item className='mb-0' style={buttonStyle}>
            <Button size='large' disabled={loading} type="primary" htmlType="submit">
              <IntlMessages id="lobby.search" />
            </Button>
          </Form.Item>
        </Space>
      </Form>
      {breakpoints.xs ? (
        <>
          <CardDataList dataList={data} totalWith={totalWith} totalDep={totalDep} />
          <div style={{ padding: '0 20px', width: '100%', textAlign:'center' }} >
            <Pagination
              current={pagination.current}
              pageSize={pagination.pageSize}
              total={pagination.total}
              onChange={handleTableChange}
              style={{ marginTop: 16, textAlign: 'right', background: 'transparent' }}
            />
          </div>
        </>
      ) : (
        <Layout style={{ background: 'transparent' }} key={'history'}>
          <Table columns={columns}
            bordered
            scroll={{ x: 'calc(700px + 50%)' }}
            dataSource={data}
            rowKey={(record) => record.txnId}
            pagination={false}
            loading={loading}
            summary={pageData => {
              let totalDepPage = 0;
              let totalWithPage = 0;
              let settleAmount = 0;
              let settleAmountAllTime = 0;
              pageData.forEach(({ action, type }) => {
                if (type === 'Deposit-mem') {
                  totalDepPage += action;
                } else {
                  totalWithPage += action;
                }
              });
              settleAmount = totalDepPage - totalWithPage;
              settleAmountAllTime = totalWith - totalDep;
              return (
                <>
                  <Table.Summary.Row key={1} style={{ background: '#e3f5ff' }}>
                    <Table.Summary.Cell colSpan={2} index={0} align='right'> <IntlMessages id="nav.total" /></Table.Summary.Cell>
                    <Table.Summary.Cell index={0} align='right'>
                      <Text >{formatNumberCurrency(totalDepPage, 2, 2)}</Text>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={0} align='right'>
                      <Text >{formatNumberCurrency(totalWithPage, 2, 2)}</Text>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={1} align='right'>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={1} align='right'>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={1} align='right'>
                    </Table.Summary.Cell>
                  </Table.Summary.Row>
                  <Table.Summary.Row key={3} style={{ background: '#e3f5ff' }}>
                    <Table.Summary.Cell colSpan={2} index={0} align='right'>Balance ( <IntlMessages id="history.withdraw" />  - <IntlMessages id="history.deposit" />)</Table.Summary.Cell>
                    <Table.Summary.Cell index={1} align='right'>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={0} align='right'>
                      <Text strong type={settleAmount > 0 ? 'success' : 'danger'}>{settleAmount > 0 ? '+' : ''} {formatNumberCurrency(settleAmount, 2, 2)} </Text>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell colSpan={3} index={1} align='right'>
                    </Table.Summary.Cell>
                  </Table.Summary.Row>

                </>
              );
            }}
          />
          <Pagination
            current={pagination.current}
            pageSize={pagination.pageSize}
            total={pagination.total}
            onChange={handleTableChange}
            style={{ marginTop: 16, textAlign: 'right', background: 'transparent' }}
          />
        </Layout>
      )}


    </div>
  )
}

