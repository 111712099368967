import React, { useEffect, useState } from 'react';
import { Layout, Grid, BackTop, FloatButton } from 'antd';
import './Template.scss'; // Import custom CSS for additional styling
import { HeaderTemplate } from './Header';
import { FooterTemplate } from './Footer/FooterTemplate';
import { getLobby, getPublicLobby, getCredit } from 'api/gameLobbyService';
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import { LobbyContent } from 'components/LobbyContent/LobbyContent';
import { HistoryCotent } from 'components/HistoryCotent/HistoryCotent';
import { ModalPromotion } from 'components/ModalPromotion/ModalPromotion';
import { useUserAccount } from 'api/useUserAccount';
import chShark from "../../assets/ch.png";
import { setAdsPopupFirstTime, setUserNameStorage } from 'api/auth';
import { FooterBar } from './FooterBar/Footerbar';
const { Header, Content, Footer } = Layout;


export const Template: React.FC = () => {
  const [gameLobby, setGameLobby] = useState([]);
  const [gameRecommend, setGameRecommend] = useState([]);
  const [wallet, setWallet] = useState(0);
  const { useBreakpoint } = Grid;
  const breakpoints = useBreakpoint();
  const navigate = useNavigate()
  const location = useLocation()
  const { account } = useUserAccount();
  const [isFirstTimeRender, setIsFirstTimeRender] = useState(true)
  const [isOpenModalPromotion, setIsOpenModalPromotion] = useState(false)

  useEffect(() => {
    getLobbyApi()
    if (account) {
      // #TODO: fix bug refresh page
      setInterval(() => {
        setIsFirstTimeRender(false)
        getCreditLoop()
      }, 30000)
    } else {
      setAdsPopupFirstTime("false")
    }
    if (location.pathname !== '/history') {
      navigate(`/lobby/hot`)
    }
  }, []);

  const getLobbyApi = async () => {
    if (account) {
      const gameLobbyRes = await getLobby()
      setGameLobby(gameLobbyRes.result.lobbyList)
      setGameRecommend(gameLobbyRes.result.hotGameList)
      setUserNameStorage(gameLobbyRes.result.username)
      setWallet(gameLobbyRes.result.credit)
    } else {
      const gameLobbyRes = await getPublicLobby()
      setGameLobby(gameLobbyRes.result)
      setWallet(0)
    }
  }

  const getCreditLoop = async () => {
    if (account) {
      const gameLobbyRes = await getCredit()
      setWallet(gameLobbyRes.result.credit)
    }
  }


  const openModalPromotion = () => {
    setIsOpenModalPromotion(true)
  }
  const closeModalPromotion = () => {
    setIsOpenModalPromotion(false)
  }


  return (
    <Layout className="layout-body">
      <div className='ch-shark'>
        <img src={chShark} alt="logoimag" />
      </div>
      <Header className='main-header' >
        <HeaderTemplate wallet={wallet} />
      </Header>

      <Content className='content-main'>
        <Routes>
          <Route path="/history" element={<HistoryCotent />} />
          <Route path="*" element={<LobbyContent gameLobby={gameLobby} recommendList={gameRecommend} isFirstTimeRender={isFirstTimeRender} />} />
        </Routes>
      </Content>

      <Footer style={{ textAlign: 'center', padding: '0px', color: '#fff' }}>
        <FooterTemplate />
      </Footer>
      <FooterBar wallet={wallet} openModalPromotion={openModalPromotion} />
      <FloatButton.BackTop visibilityHeight={400} style={{ insetBlockEnd: '83px' }} />
      <ModalPromotion isOpenModalPromotion={isOpenModalPromotion} onClose={closeModalPromotion} />
    </Layout >
  );
};


