import React, { CSSProperties, useState } from 'react';
import { Button, Flex, Grid, Layout, Table, TableColumnsType, DatePicker, Form, Space, Pagination, Tooltip, Typography, Skeleton, Empty } from 'antd';
import { searchBetList } from 'api/betHistoryService';
import moment from 'moment';
import { formatNumberCurrency } from 'utils/number';
import dayjs from 'dayjs';
import { CopyOutlined, ZoomInOutlined } from '@ant-design/icons';
import IntlMessages from 'components/Common/IntlMessages';
import { CardDataList } from './CardDataList/CardDataList';
import { useNavigate } from 'react-router-dom';
interface DataType {
  gameRef: React.Key;
  betAmount: number;
  gameCompany: string;
  settleAmount: number;
  betTime: string;
  settleTime: string;
  gameCompanyId: number;
  gameCode: string;
}

interface betHistProp {
  notiApi: any
}
const { Text } = Typography;

export const BetHistoryTab: React.FC<betHistProp> = ({ notiApi }) => {
  const { useBreakpoint } = Grid;
  const breakpoints = useBreakpoint();
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const [data, setData] = useState<DataType[]>([]);
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 50,
    total: 0,
  });
  const [sumTurnOver, setSumTurnOver] = useState(0);
  const [sumWinLoss, setSumWinLoss] = useState(0);

  const fecthBetList = async (values: any, page: number = 1, pageSize: number = 50) => {
    try {
      setLoading(true);
      const { DatePicker } = values;
      const betResult = await searchBetList(page, pageSize, Number(DatePicker[0].valueOf()), Number(DatePicker[1].valueOf()));
      setData(betResult.result.transactions)
      setPagination({
        current: betResult.result.pageIndex,
        pageSize: betResult.result.rowPerPage,
        total: betResult.result.total,
      });
      setSumTurnOver(betResult.result.turn);
      setSumWinLoss(betResult.result.winloss);
    } catch (error) {
      console.error('Failed to fetch data');
    } finally {
      setLoading(false);
    }
  }
  const onSearch = (values: any) => {
    fecthBetList(values)
  }

  const handleTableChange = (page: number, pageSize: number) => {
    const values = form.getFieldsValue();
    fecthBetList(values, page, pageSize);
  };

  const layoutLobby: CSSProperties = {
    background: 'none',
    paddingTop: (() => {
      if (!breakpoints.lg) {
        return '0'
      } else {
        return '20px'
      }
    })(),
  }

  const copyToClipboard = async (gameRef: string) => {
    await navigator.clipboard.writeText(gameRef);
    notiApi.success({
      message: `Copy to clipboard`,
      description: gameRef,
      placement: 'topRight'
    });
  };



  const columns: TableColumnsType<DataType> = [
    {
      title: <IntlMessages id="history.provider" />, dataIndex: 'gameCompany',
      onCell: (record, ) => {
        return {
          onClick: (ev) => {
            navigate(`/lobby/hot?forceOpen=${record.gameCompanyId}&forceGameCode=${record.gameCode}`)
          },
        };
      },
      render: (uuid: string) => <Tooltip placement="top" title={"คลิกเพื่อเปิดเกมดูรายละอียด"}> <Space><ZoomInOutlined /><Text style={{ maxWidth: '100px' }} ellipsis={true}>{uuid}</Text></Space> </Tooltip>
    },
    {
      title: 'ref.',
      dataIndex: 'gameRef',
      onCell: (record) => {
        return {
          onClick: (ev) => {
            copyToClipboard(`${record.gameRef}`);
          },
        };
      },
      render: (uuid: string) => <Tooltip placement="top" title={"คลิกเพื่อคัดลอก " + uuid}> <Space><CopyOutlined /><Text style={{ maxWidth: '100px' }} ellipsis={true}>{uuid}</Text></Space> </Tooltip>
    },
    {
      title: <IntlMessages id="history.betTime" />,
      dataIndex: 'betTime',
      render: (betTime) => {
        return moment(betTime).format('DD-MM-YYYY HH:mm:ss')
      }
    },
    {
      title: <IntlMessages id="history.betAmount" />,
      align: 'right',
      dataIndex: 'betAmount',
      render: (record) => {
        return formatNumberCurrency(record, 2, 2)
      }
    },
    {
      title: <IntlMessages id="history.winloss" />,
      align: 'right',
      dataIndex: 'settleAmount',
      render: (settleAmount: number, record: any) => {
        if (record.result === "PENDING") {
          return "-"
        } else {
          return <Text strong type={settleAmount > 0 ? 'success' : 'danger'}>{settleAmount > 0 ? '+' + formatNumberCurrency(settleAmount, 2, 2) : formatNumberCurrency(record.betAmount * -1, 2, 2)} </Text>
        }
      }
    },
    {
      title: <IntlMessages id="history.settleTime" />,
      dataIndex: 'settleTime',
      render: (settleTime, record: any) => {
        if (record.result === "PENDING") {
          return <IntlMessages id="history.wait" />
        } else {
          return moment(settleTime).format('DD-MM-YYYY HH:mm:ss')
        }
      }
    },
  ];
  const displayContent: CSSProperties = {
    display: (() => {
      if (!breakpoints.lg) {
        return 'block'
      } else {
        return 'flex'
      }
    })(),

  }

  const buttonStyle: CSSProperties = {
    paddingTop: (() => {
      if (!breakpoints.lg) {
        return '10px'
      } else {
        return '0px'
      }
    })(),
  }
  const { RangePicker } = DatePicker

  return (
    <div key={'bet-tab'} className='bet-table-root'>
      <Form
        form={form}
        name="control-bet"
        layout={breakpoints.xs ? 'vertical' : 'inline'}
        onFinish={onSearch}
        style={breakpoints.xs ? { marginBottom: '10px' } : { marginBottom: '30px' }}
        className='form-search'
        initialValues={{ DatePicker: [dayjs().startOf('day'), dayjs()] }}
      >
        <Space style={displayContent}>
          <Form.Item
            label={<IntlMessages id="history.searchDate" />}
            name="DatePicker"
            className='mb-0'
            // style={ { alignItems: 'center'}}
            rules={[{ required: true, message: 'Please select a date range' }]}
          >
            <RangePicker size='large'
              minDate={dayjs().subtract(32, 'days')}
              maxDate={dayjs()}
              format={'DD/MM/YYYY'}
            />
          </Form.Item>
          <Form.Item style={buttonStyle}>
            <Button size='large' disabled={loading} type="primary" htmlType="submit">
              <IntlMessages id="lobby.search" />
            </Button>
          </Form.Item>
        </Space>
      </Form>
      {breakpoints.xs ? (
        <>
          <CardDataList dataList={data} notiApi={notiApi} sumTurnOver={sumTurnOver} sumWinLoss={sumWinLoss} />
          <div style={{ padding: '0 20px', width: '100%', textAlign: 'center' }} >
            <Pagination
              current={pagination.current}
              pageSize={pagination.pageSize}
              total={pagination.total}
              onChange={handleTableChange}
              style={{ textAlign: 'right' }}
            />
          </div>
        </>
      ) : (
        <Layout style={{ background: 'transparent' }} key={'history'}>
          <Table columns={columns}
            scroll={{ x: 'calc(700px + 50%)' }}
            dataSource={data}
            rowKey={(record) => record.gameRef}
            pagination={false}
            loading={loading}
            locale={{
              emptyText: loading ? <Skeleton active={true} /> : <Empty />
            }}
            summary={pageData => {

              return (
                <>
                  <Table.Summary.Row key={1} style={{ background: '#e3f5ff' }}>
                    <Table.Summary.Cell colSpan={3} index={0} align='right'> <IntlMessages id="history.total" />,</Table.Summary.Cell>
                    <Table.Summary.Cell index={0} align='right'>
                      <Text >{formatNumberCurrency(sumTurnOver, 2, 2)}</Text>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={0} align='right'>
                      <Text strong type={sumWinLoss > 0 ? 'success' : 'danger'}>{sumWinLoss > 0 ? '+' : ''} {formatNumberCurrency(sumWinLoss, 2, 2)}</Text>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={1} align='right'>
                    </Table.Summary.Cell>
                  </Table.Summary.Row>
                </>
              );
            }}
          />
          <Pagination
            current={pagination.current}
            pageSize={pagination.pageSize}
            total={pagination.total}
            onChange={handleTableChange}
            style={{ marginTop: 16, textAlign: 'right', background: 'transparent' }}
          />
        </Layout>
      )}
    </div>
  )
}

