import { api } from "./instances";

export const GAME_API = "/lobby/launch"
export const GAME_LIST_API = "/lobby/game-list"

export async function openGame(lobbyId: number, isMobile: boolean, ip: string = "127.0.0.1", gameCode: string = "") {
  const currentHost = `${window.location.protocol}//${window.location.hostname}`;
  const response = await api.post(GAME_API, {lobbyId, mobile: isMobile, gameCode, ip: ip, homePage: currentHost});
  return response.data
}

export async function getGameList(lobbyId: number) {
  const response = await api.post(GAME_LIST_API, {lobbyId });
  return response.data
}