import { api } from "./base";
import { api as apiToken } from "./instances";
import Axios from 'axios';
// TODO: Find where to keep these constants
export const LOGIN_URL = "/auth/signin";
export const LOGOUT_URL = "/auth/signout";

export const ACCESS_TOKEN_KEY = "accessToken";
export const LOCALE_KEY = "locale";
export const MENU_SELECTED_KEY = "menuSelected";
export const USER_KEY = "user";
export const ADS_POPUP = "ads_popup";
export async function login(username: string, password: string) {
  let ipClient = "127.0.0.1"
  Axios.get('https://ipapi.co/json/').then((res) => {
    ipClient = res.data?.ip;
  });
  return api.post(LOGIN_URL, { username, password, ip: ipClient }).then((res) => {
    setToken(res?.data?.result?.bearerToken);
    setLocale('th');
    setMenuSelected('/');
    setAdsPopupFirstTime("false");
    return res?.data;
  });
}

export const setUserNameStorage = (username = "") => {
  if (typeof window === "undefined") return;
  localStorage.setItem(USER_KEY, username);
};

export const getUserNameStorage = () => {
  if (typeof window === "undefined") return;
  return localStorage.getItem(USER_KEY);
};


export async function saveToken(token: string) {
  setToken(token);
  const local = localStorage.getItem(LOCALE_KEY) ?? "th"
  if (!local) {
    setLocale('th');
  }
  setMenuSelected('/');
  return token as string;
}

export async function logout() {
  try {
    await apiToken.post(LOGOUT_URL)
  } catch (e) {
    console.log('error', e)
  } finally {
    clearTokens()
    window.location.href = '/lobby/hot'
  }
}

export const clearTokens = () => {
  localStorage.removeItem(ACCESS_TOKEN_KEY)
  localStorage.removeItem(USER_KEY)
  localStorage.removeItem(ADS_POPUP)
}

export const setToken = (accessToken = "") => {
  if (typeof window === "undefined") return;
  localStorage.setItem(ACCESS_TOKEN_KEY, JSON.stringify(accessToken));
};

export const getAccessToken = () => {
  if (typeof window === "undefined") return ""

  const jsonString = localStorage.getItem(ACCESS_TOKEN_KEY)

  return jsonString ? JSON.parse(jsonString) : undefined
}

export const getAdsPopupFirstTime = () => {
  if (typeof window === "undefined") return ""
  const jsonString = localStorage.getItem(ADS_POPUP)
  return jsonString
}

export const setAdsPopupFirstTime = (looked = "false") => {
  if (typeof window === "undefined") return ""
  localStorage.setItem(ADS_POPUP, looked)
}

export const setLocale = (locale = "th") => {
  if (typeof window === "undefined") return;
  localStorage.setItem(LOCALE_KEY, locale);
};

export const getLocale = () => {
  if (typeof window === "undefined") return "th"

  const local = localStorage.getItem(LOCALE_KEY) ?? "th"

  return local
}

export const getMenuSelected = () => {
  if (typeof window === "undefined") return "home"
  const value = localStorage.getItem(MENU_SELECTED_KEY) ?? "home"
  return value
}

export const setMenuSelected = (locale = "home") => {
  if (typeof window === "undefined") return;
  localStorage.setItem(MENU_SELECTED_KEY, locale);
};