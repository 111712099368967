import React, { useMemo, useState } from "react";
import { useUserAccount } from "api/useUserAccount";
import { logout } from "api/auth";
import { Button, Flex, Grid, Space } from "antd";
import IntlMessages from "components/Common/IntlMessages";
import './HeaderTemplate.scss';
import { Wallet } from "components/Proflie/Wallet";
import { NavModal } from "components/NavModal/NavModal";
import { LoginModal } from "components/LoginModal/LoginModal";
import { useNotification } from "components/Common/Notification";
import { useNavigate } from "react-router-dom";
import { useIntl } from "react-intl";

interface WalletProps {
    wallet: any,
}

export const HeaderTemplate: React.FC<WalletProps> = ({
    wallet,
}) => {
    const intl = useIntl();
    const agentDomain = process.env.REACT_APP_AUTO_DOMAIN
    const { account } = useUserAccount();
    // const [collapsed, setCollapsed] = useState(false);
    const [isShowModel, setIsShowModel] = useState(false);
    const { useBreakpoint } = Grid;
    const breakpoints = useBreakpoint();
    const { notification, contextNotification } = useNotification();
    const [isLoginModal, setIsLoginModal] = useState(false);

    const handelCloseLogin = () => {
        setIsLoginModal(false);
    }

    const Context = React.createContext({ name: 'Default' });
    const contextValue = useMemo(() => ({ name: 'Ant Design' }), []);


    const handelSubmitResult = async (data: boolean) => {
        // setLoading(true);
        // await login(data.username, data.password);
        // setUserNameStorage(data.username);
        if (data) {
            notification.success({
                message: <IntlMessages id="common.success" />,
                description: intl.formatMessage({ id: `message.signInSuccess` }),
                placement: 'top',
            });
            await setTimeout(async () => {
                // setLoading(false);
                setIsLoginModal(false);
                window.location.reload();
            }, 1000);
        } else {
            notification.error({
                message: <IntlMessages id="common.failed" />,
                description: intl.formatMessage({ id: `message.signInFailed` }),
                placement: 'top',
            });
        }

    };

    return (
        <div className="hearder-content" style={{ padding: breakpoints.xs ? "0 9px" : "0 50px" }}>
            <div className="logo" >
                <img alt="logo-img" style={{minWidth: '55px'}} src={'/assets/yol789.webp'} />
            </div>
            <Context.Provider value={contextValue}>
                {contextNotification}

            </Context.Provider>
            <div className="header-top-menu" style={{ display: breakpoints.xs ? "flex" : "flex" }}>
                <Button
                    type="text"
                    className="hamberger-btn"
                    onClick={() => {
                        setIsShowModel(true)
                    }}
                    style={{
                        marginLeft: '20px',
                        color: 'white',
                        zIndex: '999',
                        width: breakpoints.xs ? "25px" : "35px",
                    }}
                >
                    <span></span>
                    <span></span>
                    <span></span>
                    <span style={{ display: breakpoints.xs ? 'none' : 'block' }}></span>
                </Button>
                <Flex justify={'flex-end'} align={'center'}>
                    <Space size={breakpoints.xs ? 'small' : 'large'} align="start">

                        {account ? (
                            <Wallet wallet={wallet} />
                        ) : (
                            <>
                                <Button size="large" type="primary" className="button-line-friends" onClick={() => { window.location.href = `${agentDomain}/#/member/register`}}><IntlMessages id="menu.register" /></Button>
                                <Button size="large" type="primary" className="button-main" onClick={() => { window.location.href = `${agentDomain}`}} ><IntlMessages id="menu.login" /></Button>
                            </>
                        )}

                    </Space>
                </Flex>
            </div>
            <NavModal onClose={() => setIsShowModel(false)} isModalOpen={isShowModel} />
            <LoginModal onSubmitResult={handelSubmitResult} loginModalOpen={isLoginModal} onClose={handelCloseLogin} />
        </div>
    );
};
