import React, { CSSProperties, useEffect, useState } from 'react';
import {  Grid, Layout, notification, TableColumnsType, DatePicker, Form, Tabs } from 'antd';
import { Link, useSearchParams } from 'react-router-dom';
import { BankOutlined, HistoryOutlined, DribbbleOutlined } from '@ant-design/icons';
import type { TabsProps } from 'antd';
import './HistoryCotent.scss';
import { StatementTab } from './StatementTab/StatementTab';
import { BetHistoryTab } from './BetHistoryTab/BetHistoryTab';
import { SportHistoryTab } from './SportHistoryTab/SportHistoryTab';
import FeatherIcon from "feather-icons-react";
import IntlMessages from 'components/Common/IntlMessages';
import { useIntl } from 'react-intl';
import lobbyIcon from "assets/game.svg";

interface DataType {
  key: React.Key;
  name: string;
  age: number;
  address: string;
  address2: string;
}

const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};

export const HistoryCotent: React.FC = () => {
  const intl = useIntl();
  const { useBreakpoint } = Grid;
  const breakpoints = useBreakpoint();
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();
  const [searchParams] = useSearchParams();
  const [activeTab, setActiveTab] = useState('statement');
  const pageParam = searchParams.get('page');
  const [api, contextHolder] = notification.useNotification();

  useEffect(() => {
    if (pageParam) {
      setActiveTab(pageParam)
    }
  }, [pageParam])


  const layoutLobby: CSSProperties = {
    padding: (() => {
      if (breakpoints.xs) {
        return '5px 0px'
      } else {
        return '5px 20px'
      }
    })(),
  }

  const rootStyle: CSSProperties = {
    padding: (() => {
      if (!breakpoints.lg) {
        return '10px 5px'
      } else {
        return '30px 50px'
      }
    })(),
  }

  const items: TabsProps['items'] = [
    { key: 'statement', label: !breakpoints.xs ?  intl.formatMessage({ id: `history.transaction` }) :  intl.formatMessage({ id: `history.transactionShot` }) , children: <StatementTab notiApi={api} />, icon: <BankOutlined />, },
    { key: 'bet', label: !breakpoints.xs ?  intl.formatMessage({ id: `history.betHistory` }) :  intl.formatMessage({ id: `history.betHistoryShot` }), children: <BetHistoryTab notiApi={api} />, icon: <HistoryOutlined />, },
    // { key: 'sport', label: !breakpoints.xs ?  intl.formatMessage({ id: `history.sportHistory` }) :  intl.formatMessage({ id: `catagoryMenu.sport` }), children: <SportHistoryTab notiApi={api} />, icon: <DribbbleOutlined />, },
  ];


  const columns: TableColumnsType<DataType> = [
    { title: 'Name', dataIndex: 'name' },
    { title: 'Age', dataIndex: 'age' },
    { title: 'Address', dataIndex: 'address' },
    { title: 'Address2s', dataIndex: 'address2' },
  ];

  const dataSource = Array.from<DataType>({ length: 46 }).map<DataType>((_, i) => ({
    key: i,
    name: `Edward King ${i}`,
    age: 32,
    address: `London, Park Lane no. ${i}`,
    address2: `London, Park Lane no. ${i}`,
  }));

  const { RangePicker } = DatePicker

  const onFinish = (values: any) => {
  }

  const onReset = () => {
    form.resetFields();
  }

  const onChange = (key: string) => {
    setActiveTab(key)
  };


  return (
    <div key={'his-page'} style={rootStyle} className="layout-history">
      <Layout key={'history'} className='layout-main'>
        {/* <div style={{ width: '100%', display: 'flex', justifyContent: 'space-between', justifyItems: 'center' }}>

          <Link className='back-btn' style={{ float: 'right' }} to="/lobby/hot">Back to lobby</Link>
        </div> */}
        <div className='control-back-button'
        // style={{ width: '100%', display: 'flex', justifyContent: 'space-between', justifyItems: 'center' }}
        >
          <Link className='menu-btn' to="/lobby/hot"> <img src={lobbyIcon} alt="gameicon" className='game-icon'/> <IntlMessages id="history.backBtn" /></Link>
        </div>
        <Tabs
          style={layoutLobby}
          animated={{ inkBar: false, tabPane: false }}
          onChange={onChange}
          // defaultActiveKey={ 'statment'}
          activeKey={activeTab || 'statment'}
          items={items}
          type='card'
          moreIcon={null}
        />

      </Layout>
      {contextHolder}
    </div>
  )
}
