import { clearTokens, logout } from "./auth";
import { api } from "./instances";
import axios from 'axios';
export const LOBBY = "/user/lobby"
export const CREDIT = "/user"
export const PUBLIC_LOBBY = "/public/lobby"
export const PUBLIC_GAME_LIST = "/public/lobby/game-list"


export async function getLobby() {
  const account = localStorage.getItem('user')
  try{
    const response = await api.get(LOBBY);
    return response.data
  }catch(e){
    if(account){
      logout()
    }
    // window.location.href = '/lobby/hot'
    return 'error'
  }
}

export async function getCredit() {
  const account = localStorage.getItem('user')
  try{
    let response: { data: any } = { data: null };
    if(account){
       response = await api.get(CREDIT);
    }
    return response.data
  }catch(error: unknown){
    clearTokens()
    if (axios.isAxiosError(error)) {
      if (error.response?.status === 401) {
        window.location.href = 'https://yolo789login.com/pages/aaio/';
        return;
      }
    } else {
      return 'An unexpected error occurred'
    }
  }
}

export async function getPublicLobby() {
  const response = await api.get(PUBLIC_LOBBY);
  return response.data
}

export async function getPublicGameList(lobbyId: number) {
  const response = await api.post(PUBLIC_GAME_LIST, {lobbyId });
  return response.data
}
